// 差异管理
import Layout from "@/layout/layout.vue";

const difference = {
  // 加载路径
  path: "/difference",
  // 加载组件
  component: Layout,
  // 重定向路径
  redirect: "noRedirect",
  // 路由名称
  name: "差异管理",
  children: [
    {
      path: "catchUpCategory",
      name: "追差分类管理",
      component: () => import("@/views/difference/catchUpCategory.vue"),
      meta: { title: "追差分类管理", noCache: true }
    },
    {
      path: "platformReview",
      component: () => import("@/views/difference/platformReview/index.vue"),
      name: "平台审核差异单",
      meta: {
        title: "平台审核差异单",
        noCache: true
      }
    },
    {
      path:"process",
      name:"差异详情",
      component:()=>import("@/views/difference/platformReview/process.vue"),
      meta: {
        title:"差异详情",
        noCache: true
      }
    },
    {
      path:"storeReview",
      name:"门店审核差异单",
      component:()=>import("@/views/difference/storeReview/index.vue"),
      meta: {
        title:"门店审核差异单",
        noCache: true
      }
    },
    {
      path: "honorReview",
      component: () => import("@/views/difference/honorReview/index.vue"),
      name: "荣耀追差订单",
      meta: {
        title: "荣耀追差订单",
        noCache: true
      }
    },
    {
      path: "importRecords",
      component: () => import("@/views/difference/importRecords/index.vue"),
      name: "导入线下打款",
      meta: {
        title: "导入线下打款",
        noCache: true
      }
    },
  ]
}

export default difference;
